import React, { useState } from "react";
import LogoImg from "assets/images/logo.svg";
import { Link } from "react-router-dom";
import { XCircle } from "react-feather";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img
                src={LogoImg}
                alt="Jonathan Mwebaze Logo"
                className="h-10 w- hover:transform hover:scale-110"
              />
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <Link
                to="/works"
                className="text-gray-700 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-md font-medium"
              >
                My Works
              </Link>
              <a
                href="https://gist.github.com/jonahgeek"
                target="_blank"
                rel="noreferrer"
                className="text-gray-700 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-md font-medium"
              >
                Gists
              </a>
              {/* <Link
                to="/bored"
                className="text-gray-700 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-md font-medium"
              >
                Just for Fun
              </Link> */}
              <Link
                to="/contact"
                className="text-gray-700 hover:bg-purple-700 hover:text-white px-3 py-2 rounded-md text-md font-medium"
              >
                Contact
              </Link>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-purple-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <svg
                className={`${isMenuOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`fixed inset-0 z-40 ${isMenuOpen ? "block" : "hidden"}`}>
        <div className="bg-gray-800 opacity-99 transition-opacity ease-in-out duration-300">
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-end p-4">
              <button
                onClick={toggleMenu}
                className="text-gray-200 hover:text-white"
              >
                <XCircle className="h-8 w-8" />
              </button>
            </div>
            <div className="flex flex-col space-y-4 py-4 px-6">
              <Link
                to="/works"
                className="text-gray-200 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-md font-medium"
              >
                My Works
              </Link>

              <a
                href="https://gist.github.com/jonahgeek"
                target="_blank"
                rel="noreferrer"
                className="text-gray-200 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-md font-medium"
              >
                Gists
              </a>
              {/* <Link
                to="/bored"
                className="text-gray-200 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-md font-medium"
              >
                Just for Fun
              </Link> */}
              <Link
                to="/contact"
                className="text-gray-200 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-md font-medium"
              >
                contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
