import React from "react";

const Ball = ({ position }) => {
  return (
    <div
      className="ball bg-gray-800"
      style={{ left: position.x, top: position.y }}
    ></div>
  );
};

export default Ball;
