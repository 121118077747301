import React from "react";
import JonahAvatar from "assets/images/jonathan.png";
import PortfolioItem1 from "assets/images/momo.jpeg";
import PortfolioItem2 from "assets/images/tickerzone.png";
import PortfolioItem3 from "assets/images/ubteb.png";
import { FiCode, FiCamera, FiMessageCircle } from "react-icons/fi";
import { MainLayout } from "components/layout";
import { Link } from "react-router-dom";

export const Landing = () => {
  return (
    <MainLayout>
      <div className="min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 ">
        <img
          src={JonahAvatar}
          alt="Jonathan"
          className="md:h-32 mb-4 border-transparent transition duration-300 hover:border-r-2 hover:border-b-2 border-black border-r-0 border-b-0"
        />
        <div className="mx-2 md:mx-64 text-2xl md:text-3xl lg:text-5xl">
          Hi, I’m <span className="font-black underline">Jonathan</span> – here
          to help you craft your dreams into realities, and shape your user
          experiences.
        </div>

        <div className="md:mx-auto mt-12">
          <section className="w-full p-2 md:p-8 mb-4">
            <div className="text-3xl font-semibold text-center mb-8">
              What I Do
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:my-12 gap-8">
              <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg">
                <FiCode className="text-4xl text-purple-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Code</h3>
                <p className="text-gray-700 text-center">
                  Crafting clean and efficient code to build functional and
                  visually appealing web applications.
                </p>
              </div>
              <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg">
                <FiCamera className="text-4xl text-purple-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">UI Design</h3>
                <p className="text-gray-700 text-center">
                  Turning peoples dreams into realities through my skills of
                  design.
                </p>
              </div>
              <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg">
                <FiCamera className="text-4xl text-purple-500 mb-4" />
                <h3 className="text-xl font-semibold mb-2">Graphic Design</h3>
                <p className="text-gray-700 text-center">
                  Transforming ideas into intuitive and captivating user
                  experiences that leave a lasting impact.
                </p>
              </div>
            </div>
          </section>

          <section className="w-full rounded-lg py-8 mb-8">
            <div className="text-3xl font-semibold text-center mb-8">
              Portfolio Highlights
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:mx-12">
              <Link
                to="/mtn-momo-gateway"
                className="bg-white p-4 rounded-lg shadow-lg"
              >
                <img
                  src={PortfolioItem1}
                  alt="Portfolio Item 1"
                  className="mb-4 h-24 mx-auto"
                />
                <h4 className="text-xl font-semibold mb-2 text-center">
                  MTN MOMO Gateway
                </h4>
                <p className="text-gray-700 text-center">
                  NodeJS & Python packages that simplifies the process of
                  interacting with the MTN Mobile Money API, making it easy to
                  create API users, generate API keys.
                </p>
              </Link>
              <a
                href="https://tickerzone.com/"
                target="_blank"
                className="bg-white p-4 rounded-lg shadow-lg"
              >
                <img
                  src={PortfolioItem2}
                  alt="Portfolio Item 2"
                  className="mb-4 h-24 mx-auto"
                />
                <h4 className="text-xl font-semibold mb-2 text-center">
                  TickerZone
                </h4>
                <p className="text-gray-700 text-center">
                  A platform that makes it possible to keep track of your stock
                  investments.
                </p>
              </a>
              <a
                href="https://ubteb.go.ug/"
                target="_blank"
                className="bg-white p-4 rounded-lg shadow-lg"
              >
                <img
                  src={PortfolioItem3}
                  alt="Portfolio Item 3"
                  className="mb-4 h-24 mx-auto"
                />
                <h4 className="text-xl font-semibold mb-2 text-center">
                  UBTEB
                </h4>
                <p className="text-gray-700 text-center">
                  The Official Website for the Uganda Business and Technical
                  Examinations Board.
                </p>
              </a>
            </div>
          </section>

          <section className="w-full py-8 md:py-12">
            <div className="text-3xl font-semibold text-center mb-8">
              <span>Current Competitions</span>

              <div className="text-xs">I'm currently participating in;</div>
            </div>
            <a
              href="https://momo2023.hackerearth.com/"
              target="_blank"
              className="p-4"
            >
              <img
                src={PortfolioItem1}
                alt="Portfolio Item 1"
                className="mb-4 h-24 mx-auto"
              />
              <h4 className="text-xl font-semibold mb-2 text-center">
                MOMO 2023 Hackerthon
              </h4>
              <p className="text-gray-700 text-center">
                MTN MoMo is a groundbreaking fintech platform offering a wide
                array of innovative digital financial services to consumers and
                businesses across Africa. Our platform provides access to
                payment, e-commerce, insurance, lending, and remittance
                services, operating in 17 markets on the continent.
              </p>
            </a>
          </section>

          <section className="w-full bg-gray-900 p-6 md:p-8 md:py-24 flex flex-col rounded-lg shadow-lg mt-8 md:mt-12">
            <div className="text-3xl text-white font-semibold mb-4">
              Let's Collaborate
            </div>
            <p className="text-gray-300 text-start">
              I'm always excited to take on new projects and challenges. If
              you're looking to bring your ideas to life or enhance your digital
              presence, feel free to reach out to me. Let's create something
              amazing together!
            </p>
            <Link
              to="/contact"
              className="bg-purple-500 w-40 text-white hover:bg-purple-600 px-6 py-3 mt-6 rounded-lg shadow-md"
            >
              Get in Touch
            </Link>
          </section>
        </div>
      </div>
    </MainLayout>
  );
};
