import { MainLayout } from "components/layout";
import React from "react";
import { Link } from "react-router-dom";

export const Bored = () => {
  return (
    <MainLayout>
      <div className="flex flex-col space-y-2 mx-24">
        <div className="mb-4">
          <span className="text-orange-500 text-2xl">
            This is literally just for fun
          </span>
          <br />
          <span className="text-gray-500 text-md">
            This is where you'll find everything I work on when I'm bored, or
            when I get super bored.
          </span>
        </div>
        <Link
          to="./pong"
          className="bg-gray-200 border border-gray-300 p-8 rounded-md hover:bg-gray-300 hover:text-orange-500"
        >
          <span className="text-lg">Impossible Pong</span>
          <br />
          <span className="font-normal text-gray-500">
            The no ordinary pong. Can you win in less than 5 seconds? I dare you
          </span>
        </Link>
      </div>
    </MainLayout>
  );
};
