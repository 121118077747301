import { MainLayout } from "components/layout";
import React from "react";
import { FiCheckCircle, FiAlertCircle } from "react-icons/fi";

export const Works = () => {
  const WorkItem = ({ link, name, desc, status }) => (
    <a
      href={link ? `https://${link}` : "#"}
      target={link ? "_blank" : "_self"}
      className="md:bg-white rounded-lg md:shadow-md p-2 md:p-6 text-gray-700 hover:bg-gray-100 transition duration-300 transform hover:scale-105"
    >
      <div className="flex items-center justify-between mb-2">
        <span className="text-xl font-semibold">{name}</span>
        {status === "Live" ? (
          <FiCheckCircle className="text-green-500 text-xl" />
        ) : (
          <FiAlertCircle className="text-yellow-500 text-xl" />
        )}
      </div>
      <div className="text-xs md:text-sm mb-2">
        {link ? (
          <a
            href={`https://${link}`}
            className="text-purple-500 hover:underline whitespace-normal truncate"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link}
          </a>
        ) : (
          <span className="text-gray-500">Link Not Public</span>
        )}
      </div>
      <div className="text-sm text-gray-600 mb-2">{desc}</div>
    </a>
  );

  const worksQuery = [
    {
      name: "MOMO Gateway",
      link: "pypi.org/project/momogateway/",
      status: "Live",
      desc: "Python",
    },
    {
      name: "MOMO Gateway",
      link: "npmjs.com/package/momogateway",
      status: "Live",
      desc: "NodeJS",
    },
    {
      name: "Vainvest Ltd",
      link: "vainvest-ug.com",
      status: "Live",
      desc: "ReactJS",
    },
    {
      name: "Tickerzone Inc",
      link: "tickerzone.com",
      status: "Live",
      desc: "ReactJS",
    },
    {
      name: "UBTEB",
      link: "ubteb.go.ug",
      status: "Live",
      desc: "ReactJS",
    },
    {
      name: "UBTEB CMS",
      link: "panel.ubteb.go.ug",
      status: "Live",
      desc: "ReactJS",
    },
    {
      name: "UBTEB API",
      status: "Live",
      desc: "nodeJS, mongoDB",
    },
    {
      name: "NCDC eShop",
      link: "eshop.ncdc.go.ug",
      status: "Test",
      desc: "php, laravel 9, mySQL, java",
    },
    {
      name: "NCDC eShop API",
      status: "Live",
      desc: "php, laravel 9, mySQL, java",
    },
    {
      name: "NCDC eShop Panel",
      link: "store.ncdc.go.ug",
      status: "Test",
      desc: "ReactJS",
    },
    {
      name: "Bulungi Bwansi",
      link: "bulungibwansi.org",
      status: "Live",
      desc: "ReactJS",
    },
  ];

  return (
    <MainLayout>
      <div className="mx-4 md:mx-24 pt-12">
        <div className="text-3xl md:text-4xl text-gray-800 font-semibold text-center mb-10">
          Explore My Works
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {worksQuery.map((x, index) => (
            <WorkItem key={index} {...x} />
          ))}
        </div>
      </div>
    </MainLayout>
  );
};
