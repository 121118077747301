import { MainLayout } from "components/layout";
import React from "react";

export const Momo = () => {
  return (
    <MainLayout>
      <div className="text-3xl text-gray-800 md:text-4xl font-semibold text-center">
        MTN MOMO Gateway Packages
      </div>
      <div className="py-8">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg">
            <div className="mb-6">
              <h3 className="text-xl md:text-2xl font-semibold mb-2">
                NPM Package: mtn-momo-gateway
              </h3>
              <p className="text-gray-700 mb-6">
                A Node.js library that simplifies the process of interacting
                with the MTN Mobile Money (MoMo) API. It facilitates the
                creation of API users, generation of API keys, and obtaining
                bearer tokens for secure transactions.
              </p>

              <a
                href="https://www.npmjs.com/package/momogateway"
                target="_blank"
                className="border border-purple-500 p-2 rounded-md"
              >
                https://www.npmjs.com/package/momogateway
              </a>
            </div>

            <div className="mb-6">
              <h3 className="text-xl md:text-2xl font-semibold mb-2">
                Python Package: momogateway
              </h3>
              <p className="text-gray-700 mb-6">
                A Python package that provides an interface for interacting with
                the MomoGateway API. It enables the creation of API users,
                generation of API keys, and obtaining bearer tokens for
                authentication.
              </p>

              <a
                href="https://pypi.org/project/momogateway/"
                target="_blank"
                className="border border-purple-500 p-2 rounded-md"
              >
                https://pypi.org/project/momogateway/
              </a>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
