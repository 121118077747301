import { MainLayout } from "components/layout";
import React from "react";
import {
  Phone,
  Mail,
  Dribbble,
  Instagram,
  Twitter,
  Linkedin,
} from "react-feather";
import { TypeAnimation } from "react-type-animation";

export const Contact = () => {
  return (
    <MainLayout>
      <div className="mx-10 md:mx-24 pt-12 flex flex-col justify-center">
        <div className="text-3xl text-gray-800 md:text-4xl font-semibold text-center">
          Get in Touch
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
          <ContactCard
            icon={<Phone className="w-10 h-10 text-purple-600" />}
            title="Business WhatsApp"
            info="+256782692271"
          />
          <ContactCard
            icon={<Mail className="w-10 h-10 text-purple-600" />}
            title="Personal Email"
            info="jonahgeek@gmail.com"
          />
          <ContactCard
            icon={<Mail className="w-10 h-10 text-purple-600" />}
            title="Work Email"
            info="jonathan@tickerzone.com"
          />
        </div>
        <div className="mt-12 text-center text-gray-800">
          <TypeAnimation
            sequence={[
              "Got a question?",
              1500,
              "Ready to discuss your project?",
              1500,
              "Want to collaborate on something amazing?",
              1500,
            ]}
            wrapper="div"
            cursor={true}
            repeat={Infinity}
          />
        </div>
        <div className="mt-12 text-center">
          <div className="flex justify-center space-x-4">
            <SocialLink
              icon={<Dribbble />}
              link="https://dribbble.com/jonahgeek"
            />
            <SocialLink
              icon={<Instagram />}
              link="https://www.instagram.com/jonahgeek_/"
            />
            <SocialLink
              icon={<Twitter />}
              link="https://twitter.com/jonahgeek"
            />
            <SocialLink
              icon={<Linkedin />}
              link="https://www.linkedin.com/in/jonahgeek/"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

const ContactCard = ({ icon, title, info }) => {
  return (
    <div className="md:bg-white p-2 md:p-6 rounded-lg md:shadow-md hover:shadow-lg transition duration-300 transform hover:-translate-y-1">
      <div className="flex items-center">
        <div className="mr-4">{icon}</div>
        <div>
          <div className="text-lg md:text-xl font-semibold mb-1">{title}</div>
          <div className="text-gray-600 text-xs md:text-md">{info}</div>
        </div>
      </div>
    </div>
  );
};

const SocialLink = ({ icon, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-600 hover:text-purple-500"
    >
      {icon}
    </a>
  );
};
