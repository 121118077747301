import React, { useState, useEffect } from "react";
import "index.css";
import Paddle from "../components/Paddle";
import Ball from "../components/Ball";
import { Link } from "react-router-dom";
import { MapPin, Send } from "react-feather";

export const Pong = () => {
  const [paddlePosition, setPaddlePosition] = useState({
    x: 0,
    y: 0,
  });

  const [ballPosition, setBallPosition] = useState({
    x: 400,
    y: 200,
    dx: 4,
    dy: 4,
  });

  const [score, setScore] = useState(0);
  const [gameStatus, setGameStatus] = useState("running");
  const [timerExpired, setTimerExpired] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [keyPattern, setKeyPattern] = useState("");

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPaddlePosition({
        x: event.clientX,
        y: event.clientY - window.scrollY,
      });
    };

    const handleKeyDown = (event) => {
      event.preventDefault(); // Prevent default scrolling behavior
      if (event.key === "ArrowUp") {
        setPaddlePosition((prevPosition) => ({
          ...prevPosition,
          y: prevPosition.y - 10,
        }));
      } else if (event.key === "ArrowDown") {
        setPaddlePosition((prevPosition) => ({
          ...prevPosition,
          y: prevPosition.y + 10,
        }));
      }

      // Add the pressed key to the key pattern
      setKeyPattern((prevPattern) => prevPattern + event.key);
    };

    const handleKeyUp = () => {
      // Reset paddle position when the arrow keys are released
      setPaddlePosition((prevPosition) => ({
        ...prevPosition,
        y: 0,
      }));
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      setBallPosition((prevPosition) => {
        let newBallPosition = {
          ...prevPosition,
          x: prevPosition.x + prevPosition.dx,
          y: prevPosition.y + prevPosition.dy,
        };

        // Check for collision with walls
        if (
          newBallPosition.x + newBallPosition.dx < 0 ||
          newBallPosition.x + newBallPosition.dx > window.innerWidth
        ) {
          newBallPosition.dx *= -1;
        }

        if (
          newBallPosition.y + newBallPosition.dy < 0 ||
          newBallPosition.y + newBallPosition.dy > window.innerHeight
        ) {
          newBallPosition.dy *= -1;
        }

        // Check for collision with paddle
        const paddleLeft = paddlePosition.x;
        const paddleRight = paddlePosition.x + 10;
        const paddleTop = paddlePosition.y;
        const paddleBottom = paddlePosition.y + 100;

        const ballLeft = newBallPosition.x;
        const ballRight = newBallPosition.x + 20;
        const ballTop = newBallPosition.y;
        const ballBottom = newBallPosition.y + 20;

        if (
          ballRight >= paddleLeft &&
          ballLeft <= paddleRight &&
          ballBottom >= paddleTop &&
          ballTop <= paddleBottom
        ) {
          newBallPosition.dx *= -1;
          setScore((prevScore) => {
            const updatedScore = prevScore + 1;
            if (updatedScore === 100) {
              setGameStatus("won");
            }
            return updatedScore;
          });
        }

        return newBallPosition;
      });
    }, 16);

    return () => {
      clearInterval(gameLoop);
    };
  }, [paddlePosition, score]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (score < 100) {
        setTimerExpired(true);
        setGameStatus("lost");
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [score]);

  useEffect(() => {
    if (keyPattern.includes("420")) {
      setShowDialog(true);
    }
  }, [keyPattern]);

  const closeDialog = () => {
    setShowDialog(false);
    setKeyPattern("");
  };

  return (
    <div className="App">
      {gameStatus === "running" && (
        <>
          <Paddle position={paddlePosition} />
          <Ball position={ballPosition} />

          <div className="score text-orange-500">
            Impossible Pong | Score: {score}
          </div>
        </>
      )}

      {showDialog && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex justify-center items-center z-50">
          <div className="bg-gray-900 rounded-lg w-full h-full flex flex-col">
            <div className="flex-none p-2 flex justify-between items-center bg-gray-800">
              <span className="text-white text-2xl">420 Chatroom</span>
              <button className="dialog-close text-white" onClick={closeDialog}>
                Close
              </button>
            </div>
            <div className="flex-grow p-4">
              <div className="h-96 bg-black rounded-md text-white overflow-y-auto">
                {/* Chatroom content */}
              </div>
            </div>
            <div className="flex-none p-4">
              <div className="flex items-center">
                <input
                  type="text"
                  className="w-full bg-gray-800 text-white border border-gray-700 rounded-md h-12 px-4"
                />
                <button className="text-white mx-8">
                  <Send />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {gameStatus !== "running" && (
        <div className="game-over">
          <div className="message text-orange-500 text-center">
            {gameStatus === "won" ? "You Won!" : "You Lose!"}
          </div>
          <div className="cheers text-center">
            <span
              role="img"
              aria-label="cheer"
              className="cheer-emoji text-center"
              style={{
                animation: "cheersAnimation 1s infinite",
              }}
            >
              {gameStatus === "won" ? "🥂" : "😔"}
            </span>
          </div>
          <div className="flex flex-row space-x-2 items-center mt-2">
            <button
              onClick={() => {
                setGameStatus("running");
                setScore(0);
                setKeyPattern(""); // Reset the key pattern
              }}
              className="text-sm bg-orange-500 p-2 px-4 rounded-md shadow"
            >
              Play again?
            </button>
            <Link to="/" className="text-sm text-orange-500">
              Go home
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
