import { Bored } from "features/bored";
import { Contact } from "features/contact";
import { DaysOfDesign } from "features/freebies";
import { Landing, Momo } from "features/home";
import { Pong } from "features/pong";
import { Works } from "features/works";
import { useRoutes } from "react-router-dom";

export const AppRoutes = () => {
  const commonRoutes = [
    { path: "/", element: <Landing /> },
    {
      path: "/100-days-of-design",
      element: <DaysOfDesign />,
    },
    {
      path: "/bored",
      element: <Bored />,
    },
    {
      path: "/bored/pong",
      element: <Pong />,
    },
    {
      path: "/mtn-momo-gateway",
      element: <Momo />,
    },
    {
      path: "/works",
      element: <Works />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
  ];

  // const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
