import React from "react";

const Paddle = ({ position }) => {
  const paddleStyle = {
    left: position.x + "px",
    top: position.y + "px",
  };

  return (
    <div className="paddle bg-gray-800 rounded-full" style={paddleStyle}></div>
  );
};

export default Paddle;
