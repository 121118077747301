import React from "react";
import { Navbar } from "./components/Navbar";

export const MainLayout = ({ children }) => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <div className="md:py-12">
        <div className="mx-4 md:mx-24"> {children}</div>
        <footer className="text-center text-gray-800 mt-12 pb-12">
          &copy; {new Date().getFullYear()} Mwebaze.com. All rights reserved.
        </footer>
      </div>
    </div>
  );
};
