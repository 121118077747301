import { MainLayout } from "components/layout";
import React from "react";
import { Dribbble, Instagram, Phone } from "react-feather";

export const DaysOfDesign = () => {
  return (
    <MainLayout>
      <div className="text-orange-600 text-3xl mt-10 text-center">100 Days Of UI Design</div>
      <div className="mx-2 md:mx-24 my-12 bg-white shadow rounded-md p-4">
        <div className="text-lg md:text-3xl mb-4">
          I am publicly committing to 100 Days UI Challenge starting March 31,
          2023.
        </div>

        <div className="text-sm md:text-lg mb-3">
          Within the 100 days, I will be creating brief designs using figma. I think
          it’s a great opportunity for me to improve on my design skills and at
          the same time put myself out there 😁!
        </div>

        <div className="text-sm md:text-lg mb-3">
          Layouts will be posted every day on Dribbble, Instagram, Twitter &
          Business WhatsApp. Stay tuned!
        </div>

        <div className="text-sm md:text-lg mb-3">
          Want me to make a specific layout? You can share your design ideas
          through my personal email address{" "}
          <a
            href="mailto:jonahgeek@gmail.com"
            className="hover:underline text-gray-600 hover:text-orange-600"
          >
            jonahgeek@gmail.com
          </a>
          .
        </div>

        <div className="my-4 flex flex-row space-x-2">
          <a href="https://dribbble.com/jonahgeek" className="hover:text-orange-500" target="_blank" alt="">
            <Dribbble className="h-8 w-8" />
          </a>
          <a href="https://instagram.com/jonahgeek_" className="hover:text-orange-500" target="_blank" alt="">
            <Instagram className="h-8 w-8" />
          </a>
          <a href="https://wa.me/+256703040046" className="hover:text-orange-500" alt="" target="_blank">
            <Phone className="h-8 w-8" />
          </a>
        </div>
      </div>
      <div className="mx-2 md:mx-24 bg-white shadow rounded-md p-4 mb-2">
        <div className="text-xl text-gray-700">Day 001</div>
        <div className="text-md text-gray-700">Scheduled for today</div>
      </div>
      <div className="mx-2 md:mx-24 bg-white shadow rounded-md p-4 mb-2 opacity-70">
        <div className="text-xl text-gray-700">Day 002</div>
        <div className="text-md text-gray-700">Scheduled for 1st April</div>
      </div>
      <div className="mx-2 md:mx-24 bg-white shadow rounded-md p-4 mb-2 opacity-40">
        <div className="text-xl text-gray-700">Day 003</div>
        <div className="text-md text-gray-700">Scheduled for 2nd April</div>
      </div>
      <div className="mx-2 md:mx-24 bg-white shadow rounded-md p-4 mb-2 opacity-10">
        <div className="text-xl text-gray-700">Day 004</div>
        <div className="text-md text-gray-700">Scheduled for 3rd April</div>
      </div>
    </MainLayout>
  );
};
